import { enableProdMode } from '@angular/core';
import { allQueryParams } from '@aston/foundation';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { currentTenantId } from './app/authentication-module/functions/utils.functions';

// check/store the tenant id before any redirection
console.log(`[init] app as T${currentTenantId()}`);

// global debugging flags
const qp = allQueryParams()
const local = !!location.host.match(/localhost/);
window['__aston_debug_inactivity'] = qp.aston_debug_inactivity;
window['__aston_force_inactivity'] = qp.aston_force_inactivity;
window['__aston_debug_auth'] = local || qp.aston_debug_auth;
window['__aston_debug_ws'] = local || qp.aston_debug_ws;
window['__aston_debug_start'] = qp.aston_debug_start;

if (environment.production || environment.staging) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
								.catch(err => console.error(err));
